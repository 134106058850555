import React from "react"
import { blogs } from "../../content/Blogs"
import ContactSection from "../../components/common/ContactSection"
import InnerBlogPage from "../../components/molecules/InnerBlogPage"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const slug = `choose-right-sda-provider-in-australia`

const blogPost = blogs.find(post => post.slug === slug) // Find the blog post by slug

const FacingChallengesInYourBusiness = () => (
  <Layout>
    <Seo
      title="Choosing the Best SDA Provider in Australia: Key Factors"
      description="Discover what to look for when choosing the right SDA provider in Australia. Find key factors that ensure quality, compliance, and resident satisfaction."
    />

    <InnerBlogPage post={blogPost} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default FacingChallengesInYourBusiness
